import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import globalVariables from "./Global.js";
import axios from 'axios';



// Import components using lazy loading


//Theme1
const MasterLayoutT1 = lazy(() => import('./Theme1/ui-components/MasterLayout'));
const DashboardT1 = lazy(() => import('./Theme1/ui-components/Dashboard'));
const SlideImagesT1 = lazy(() => import('./Theme1/ui-components/SlideImages'));
const ParishNewsT1 = lazy(() => import('./Theme1/ui-components/ParishNews'));
const ChurchesT1 = lazy(() => import('./Theme1/ui-components/Churches'));
const PriestsT1 = lazy(() => import('./Theme1/ui-components/Priests'));
const ParishHistoryT1 = lazy(() => import('./Theme1/ui-components/ParishHistory'));
const NoDataFoundT1 = lazy(() => import('./Theme1/ui-components/NoDataFound'));
const ContactT1 = lazy(() => import('./Theme1/ui-components/Contact'));
const HelpDeskT1 = lazy(() => import('./Theme1/ui-components/HelpDesk'));
const ParishTimingT1 = lazy(() => import('./Theme1/ui-components/ParishTiming'));
const PrayersT1= lazy(() => import('./Theme1/ui-components/Prayers'));
const ServiceTeam1 = lazy(() => import('./Theme1/ui-components/ServiceTeam'));
const EmployeeAtCall1= lazy(() => import('./Theme1/ui-components/EmployeeAtCall'));
const HealthcareAtCall1= lazy(() => import('./Theme1/ui-components/HealthcareAtCall'));
const ShopAtCall1= lazy(() => import('./Theme1/ui-components/ShopAtCall'));
const VicarsMessage1= lazy(() => import('./Theme1/ui-components/VicarsMessage'));
const Chronicles1= lazy(() => import('./Theme1/ui-components/Chronicles'));
const Shrine1= lazy(() => import('./Theme1/ui-components/Shrine'));
const Institutions1= lazy(() => import('./Theme1/ui-components/Institutions'));
const Organization1= lazy(() => import('./Theme1/ui-components/Organization'));
const FilialChurch1= lazy(() => import('./Theme1/ui-components/FilialChurch'));
const Establishment1= lazy(() => import('./Theme1/ui-components/Establishment'));
const Publication1= lazy(() => import('./Theme1/ui-components/Publication'));
const ParishBulletin1= lazy(() => import('./Theme1/ui-components/ParishBulletin'));
const SingleNews1= lazy(() => import('./Theme1/ui-components/SingleNews'));
const SingleParishNews1= lazy(() => import('./Theme1/ui-components/SingleParishNews'));
const SinglePrayer1= lazy(() => import('./Theme1/ui-components/SinglePrayer'));
const PrayerHeaders1= lazy(() => import('./Theme1/ui-components/PrayerHeaders'));
const SingleInstitution1= lazy(() => import('./Theme1/ui-components/SingleInstitution'));
const SingleFilialChurch1= lazy(() => import('./Theme1/ui-components/SingleFilialChurch'));
const OnlineMarket1= lazy(() => import('./Theme1/ui-components/OnlineMarket'));
const JobVaccancy1= lazy(() => import('./Theme1/ui-components/JobVaccancy'));
const JobSeekers1= lazy(() => import('./Theme1/ui-components/JobSeekers'));
const SingleJobVaccancy1= lazy(() => import('./Theme1/ui-components/SingleJobVaccancy'));
const AllParishNews1= lazy(() => import('./Theme1/ui-components/AllParishNews'));
const ParishContacts1= lazy(() => import('./Theme1/ui-components/ParishContacts'));
const ParishDetails1= lazy(() => import('./Theme1/ui-components/ParishDetails'));
const ParishImage1= lazy(() => import('./Theme1/ui-components/ParishImage'));
const ParishDescription1= lazy(() => import('./Theme1/ui-components/ParishDescription'));
const SingleVicarMessage1= lazy(() => import('./Theme1/ui-components/SingleVicarMessage'));
const ParishCalendar1= lazy(() => import('./Theme1/ui-components/ParishCalendar'));
const LiturgicalCalender1= lazy(() => import('./Theme1/ui-components/LiturgicalCalender'));
const DashboardHistory1= lazy(() => import('./Theme1/ui-components/DashboardHistory'));
const DasboardVicarsMessage1= lazy(() => import('./Theme1/ui-components/DasboardVicarsMessage'));
const DashboardCategories1= lazy(() => import('./Theme1/ui-components/DashboardCategories'));
const Marquee1= lazy(() => import('./Theme1/ui-components/Marquee'));
const MarqueeHorizontal1= lazy(() => import('./Theme1/ui-components/MarqueeHorizontal'));
const Birthday1= lazy(() => import('./Theme1/ui-components/Birthday'));
const QuickStatus1= lazy(() => import('./Theme1/ui-components/QuickStatus'));
const ParishSummary1= lazy(() => import('./Theme1/ui-components/ParishSummary'));
const Wedding1= lazy(() => import('./Theme1/ui-components/Wedding'));
const DashboardNotifications1= lazy(() => import('./Theme1/ui-components/DashboardNotifications'));
//const PageNotFound = lazy(() => import('./PageNotFound'));
const LeadingLights1= lazy(() => import('./Theme1/ui-components/LeadingLights'));
const Celebrations1= lazy(() => import('./Theme1/ui-components/Celebrations'));

//Theme2
const MasterLayout2 = lazy(() => import('./Theme2/ui-components/MasterLayout'));
const Dashboard2 = lazy(() => import('./Theme2/ui-components/Dashboard'));
const SlideImages2 = lazy(() => import('./Theme2/ui-components/SlideImages'));
const ParishNews2 = lazy(() => import('./Theme2/ui-components/ParishNews'));
const Churches2 = lazy(() => import('./Theme2/ui-components/Churches'));
const Priests2 = lazy(() => import('./Theme2/ui-components/Priests'));
const ParishHistory2 = lazy(() => import('./Theme2/ui-components/ParishHistory'));
const NoDataFound2 = lazy(() => import('./Theme2/ui-components/NoDataFound'));
const Contact2 = lazy(() => import('./Theme2/ui-components/Contact'));
const HelpDesk2= lazy(() => import('./Theme2/ui-components/HelpDesk'));
const ParishTiming2 = lazy(() => import('./Theme2/ui-components/ParishTiming'));
const Prayers2 = lazy(() => import('./Theme2/ui-components/Prayers'));
const ServiceTeam2 = lazy(() => import('./Theme2/ui-components/ServiceTeam'));
const VicarsMessage2 = lazy(() => import('./Theme2/ui-components/VicarsMessage'));
const Chronicles2= lazy(() => import('./Theme2/ui-components/Chronicles'));
const Shrine2= lazy(() => import('./Theme2/ui-components/Shrine'));
const Institutions2= lazy(() => import('./Theme2/ui-components/Institutions'));
const Organization2= lazy(() => import('./Theme2/ui-components/Organization'));
const FilialChurch2= lazy(() => import('./Theme2/ui-components/FilialChurch'));
const Establishment2= lazy(() => import('./Theme2/ui-components/Establishment'));
const Publication2= lazy(() => import('./Theme2/ui-components/Publication'));
const ParishBulletin2= lazy(() => import('./Theme2/ui-components/ParishBulletin'));
const SingleNews2= lazy(() => import('./Theme2/ui-components/SingleNews'));
const SingleParishNews2= lazy(() => import('./Theme2/ui-components/SingleParishNews'));
const SinglePrayer2= lazy(() => import('./Theme2/ui-components/SinglePrayer'));
const PrayerHeaders2= lazy(() => import('./Theme2/ui-components/PrayerHeaders'));
const SingleInstitution2= lazy(() => import('./Theme2/ui-components/SingleInstitution'));
const SingleFilialChurch2= lazy(() => import('./Theme2/ui-components/SingleFilialChurch'));
const OnlineMarket2= lazy(() => import('./Theme2/ui-components/OnlineMarket'));
const JobVaccancy2= lazy(() => import('./Theme2/ui-components/JobVaccancy'));
const JobSeekers2= lazy(() => import('./Theme2/ui-components/JobSeekers'));
const SingleJobVaccancy2= lazy(() => import('./Theme2/ui-components/SingleJobVaccancy'));
const AllParishNews2= lazy(() => import('./Theme2/ui-components/AllParishNews'));
const ParishContacts2= lazy(() => import('./Theme2/ui-components/ParishContacts'));
const ParishDetails2= lazy(() => import('./Theme2/ui-components/ParishDetails'));
const ParishImage2= lazy(() => import('./Theme2/ui-components/ParishImage'));
const ParishDescription2= lazy(() => import('./Theme2/ui-components/ParishDescription'));
const SingleVicarMessage2= lazy(() => import('./Theme2/ui-components/SingleVicarMessage'));
const EmployeeAtCall2= lazy(() => import('./Theme2/ui-components/EmployeeAtCall'));
const HealthcareAtCall2= lazy(() => import('./Theme2/ui-components/HealthcareAtCall'));
const ShopAtCall2= lazy(() => import('./Theme2/ui-components/ShopAtCall'));
const ParishCalendar2= lazy(() => import('./Theme2/ui-components/ParishCalendar'));
const LiturgicalCalender2= lazy(() => import('./Theme2/ui-components/LiturgicalCalender'));
const DashboardCategories2= lazy(() => import('./Theme2/ui-components/DashboardCategories'));
const DashboardHistory2= lazy(() => import('./Theme2/ui-components/DashboardHistory'));
const MarqueeHorizontal2= lazy(() => import('./Theme2/ui-components/MarqueeHorizontal'));
const QuickStatus2= lazy(() => import('./Theme2/ui-components/QuickStatus'));
const ParishVideos2= lazy(() => import('./Theme2/ui-components/ParishVideos.js'));
const ParishSummary2= lazy(() => import('./Theme2/ui-components/ParishSummary'));
const Birthday2= lazy(() => import('./Theme2/ui-components/Birthday'));
const Wedding2= lazy(() => import('./Theme2/ui-components/Wedding'));
const Celebrations2= lazy(() => import('./Theme2/ui-components/Celebrations'));
const LatestNews2= lazy(() => import('./Theme2/ui-components/LatestNews'));
const LeadingLights2= lazy(() => import('./Theme2/ui-components/LeadingLights'));
const SingleShop2= lazy(() => import('./Theme2/ui-components/SingleShop'));

function App() {
  const [parishTheme, setParishTheme] = useState('');
  const [loading, setLoading] = useState(true);
const currentURL = window.location.href;
const domain = new URL(currentURL).hostname;
 //const domain = 'nazarethstjeromes.archechry.org';
//const domain = 'punalursacretheart.archechry.org';
  // const domain = 'chrystalphonsa.archechry.org';
 // console.log(domain);
  const getParishID_domain = () => {
 
    return new Promise((resolve, reject) => {
      try {
       
        if (domain) {

          const form_data = {
            domain: domain,
           
          };
        
          axios.post(globalVariables.mobile_api + '/Parishwebsite_api/getParishID_domain', form_data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .then(response => {
            const response_data = response.data;
            
            resolve(response_data);
          
          })
          .catch(error => {
            console.error('Error verifying server token:', error);
            reject(error);
          });
        }
      } catch (error) {
        console.error('Error verifying server token:', error);
        reject(error);
      }
    });
  };
  
  useEffect(() => {   
   
    getParishID_domain()
      .then(response_data => {
        if (response_data && response_data.success === true) {
         
          localStorage.setItem('response_data', JSON.stringify(response_data));
          localStorage.setItem('parish_id', response_data.parish_id);
          localStorage.setItem('parish_name', response_data.parish_name);
          axios.defaults.headers.common['Authorization'] = `Bearer ${response_data.parish_id}`;
          setParishTheme(response_data.parish_theme);
        

        } else {
          setParishTheme('');
          
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
       
        localStorage.removeItem('lastVisitedPage');
        console.error('Error in verifyServerToken:', error);
      });
     
}, []);

  const getRoutesForTheme = parishTheme => {
    //console.log(parishTheme)
    switch (parishTheme) {
      case 'THEME_1':
        return (
          <>
          <Route element={<MasterLayoutT1 />}>
           <Route path="/" element={<DashboardT1 />} />
           <Route path="slideimages" element={<SlideImagesT1 />} />
           <Route path="parishnews" element={<ParishNewsT1 />} />
           <Route path="churches" element={<ChurchesT1 />} />
           <Route path="priests" element={<PriestsT1 />} />
           <Route path="nodatafound" element={<NoDataFoundT1 />} />
           <Route path="parishhistory" element={<ParishHistoryT1 />} />
           <Route path="Contact" element={<ContactT1 />} />
           <Route path="HelpDesk" element={<HelpDeskT1 />} />
           <Route path="ParishTiming" element={<ParishTimingT1 />} />
           <Route path="Prayers" element={<PrayersT1 />} />
           <Route path="ServiceTeam" element={<ServiceTeam1 />} />
           <Route path="VicarsMessage" element={<VicarsMessage1 />} />
           <Route path="EmployeeAtCall" element={<EmployeeAtCall1 />} />
           <Route path="HealthcareAtCall" element={<HealthcareAtCall1 />} />
           <Route path="ShopAtCall" element={<ShopAtCall1 />} />
           <Route path="Chronicles" element={<Chronicles1 />} />
           <Route path="Shrine" element={<Shrine1 />} />
           <Route path="Institutions" element={<Institutions1 />} />
           <Route path="Organization" element={<Organization1 />} />
           <Route path="FilialChurch" element={<FilialChurch1 />} />
           <Route path="Establishment" element={<Establishment1 />} />
           <Route path="ParishBulletin" element={<ParishBulletin1 />} />
           <Route path="Publication" element={<Publication1 />} />
           <Route path="SingleNews" element={<SingleNews1 />} />
           <Route path="SingleParishNews" element={<SingleParishNews1 />} />
           <Route path="PrayerHeaders" element={<PrayerHeaders1 />} />
           <Route path="SinglePrayer" element={<SinglePrayer1 />} />
           <Route path="SingleInstitution" element={<SingleInstitution1 />} />
           <Route path="OnlineMarket" element={<OnlineMarket1 />} />
           <Route path="SingleFilialChurch" element={<SingleFilialChurch1 />} />
           <Route path="JobSeekers" element={<JobSeekers1 />} />
           <Route path="JobVaccancy" element={<JobVaccancy1 />} />
           <Route path="SingleJobVaccancy" element={<SingleJobVaccancy1 />} />
           <Route path="AllParishNews" element={<AllParishNews1 />} />
           <Route path="ParishDetails" element={<ParishDetails1 />} />
           <Route path="ParishContacts" element={<ParishContacts1 />} />
           <Route path="ParishImage" element={<ParishImage1 />} />
           <Route path="ParishDescription" element={<ParishDescription1 />} />
           <Route path="SingleVicarMessage" element={<SingleVicarMessage1 />} />
           <Route path="ParishCalendar" element={<ParishCalendar1 />} />
           <Route path="LiturgicalCalender" element={<LiturgicalCalender1 />} />
           <Route path="DashboardHistory" element={<DashboardHistory1 />} />
           <Route path="DasboardVicarsMessage" element={<DasboardVicarsMessage1 />} />
           <Route path="DashboardCategories" element={<DashboardCategories1 />} />
           <Route path="Marquee" element={<Marquee1 />} />
           <Route path="MarqueeHorizontal" element={<MarqueeHorizontal1 />} />
           <Route path="Birthday" element={<Birthday1 />} />
           <Route path="QuickStatus" element={<QuickStatus1 />} />
           <Route path="ParishSummary" element={<ParishSummary1 />} />
           <Route path="Wedding" element={<Wedding1 />} />
           <Route path="DashboardNotifications" element={<DashboardNotifications1 />} />
           <Route path="LeadingLights" element={<LeadingLights1 />} />
           <Route path="Celebrations" element={<Celebrations1 />} />
         </Route>
         </>
        );
      case 'THEME_2':
       
        return (
          
          <>
          
          <Route element={<MasterLayout2 />}>
           <Route path="/" element={<Dashboard2 />} />
           <Route path="slideimages" element={<SlideImages2 />} />
           <Route path="parishnews" element={<ParishNews2 />} />
           <Route path="churches" element={<Churches2 />} />
           <Route path="priests" element={<Priests2 />} />
           <Route path="nodatafound" element={<NoDataFound2 />} />
           <Route path="parishhistory" element={<ParishHistory2 />} />
           <Route path="Contact" element={<Contact2 />} />
           <Route path="HelpDesk" element={<HelpDesk2 />} />
           <Route path="ParishTiming" element={<ParishTiming2 />} />
           <Route path="Prayers" element={<Prayers2 />} />
           <Route path="ServiceTeam" element={<ServiceTeam2 />} />
           <Route path="VicarsMessage" element={<VicarsMessage2 />} />
           <Route path="EmployeeAtCall" element={<EmployeeAtCall2 />} />
           <Route path="HealthcareAtCall" element={<HealthcareAtCall2 />} />
           <Route path="ShopAtCall" element={<ShopAtCall2 />} />
           <Route path="Chronicles" element={<Chronicles2 />} />
           <Route path="Shrine" element={<Shrine2 />} />
           <Route path="Institutions" element={<Institutions2 />} />
           <Route path="Organization" element={<Organization2 />} />
           <Route path="FilialChurch" element={<FilialChurch2 />} />
           <Route path="Establishment" element={<Establishment2 />} />
           <Route path="ParishBulletin" element={<ParishBulletin2 />} />
           <Route path="Publication" element={<Publication2 />} />
           <Route path="SingleNews" element={<SingleNews2 />} />
           <Route path="SingleParishNews" element={<SingleParishNews2 />} />
           <Route path="PrayerHeaders" element={<PrayerHeaders2 />} />
           <Route path="SinglePrayer" element={<SinglePrayer2 />} />
           <Route path="SingleInstitution" element={<SingleInstitution2 />} />
           <Route path="OnlineMarket" element={<OnlineMarket2 />} />
           <Route path="SingleFilialChurch" element={<SingleFilialChurch2 />} />
           <Route path="JobSeekers" element={<JobSeekers2 />} />
           <Route path="JobVaccancy" element={<JobVaccancy2 />} />
           <Route path="SingleJobVaccancy" element={<SingleJobVaccancy2 />} />
           <Route path="AllParishNews" element={<AllParishNews2 />} />
           <Route path="ParishDetails" element={<ParishDetails2 />} />
           <Route path="ParishContacts" element={<ParishContacts2 />} />
           <Route path="ParishImage" element={<ParishImage2 />} />
           <Route path="ParishDescription" element={<ParishDescription2 />} />
           <Route path="SingleVicarMessage" element={<SingleVicarMessage2 />} />
           <Route path="ParishCalendar" element={<ParishCalendar2 />} />
           <Route path="LiturgicalCalender" element={<LiturgicalCalender2 />} />
           <Route path="DashboardCategories" element={<DashboardCategories2 />} />
           <Route path="DashboardHistory" element={<DashboardHistory2 />} />
           <Route path="MarqueeHorizontal" element={<MarqueeHorizontal2 />} />
           <Route path="QuickStatus" element={<QuickStatus2 />} />
           <Route path="ParishVideos" element={<ParishVideos2 />} />
           <Route path="ParishSummary" element={<ParishSummary2 />} />
           <Route path="Wedding" element={<Wedding2 />} />
           <Route path="Birthday" element={<Birthday2 />} />
           <Route path="Celebrations" element={<Celebrations2 />} />
           <Route path="LatestNews" element={<LatestNews2 />} />
           <Route path="LeadingLights" element={<LeadingLights2 />} />
           <Route path="SingleShop" element={<SingleShop2 />} />
           
           </Route>
         </>
        );
       
        default:
          return null;
    }
  };

  return (
    <div>
    <BrowserRouter>
      <Suspense fallback={
        <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      }>
        <Routes>
          {!loading && getRoutesForTheme(parishTheme)}
          
        </Routes>
      </Suspense>
    </BrowserRouter>
  </div>
  );
}

export default App;
